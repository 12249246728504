import { handleErrorWithSentry, Replay } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import { dev } from '$app/environment';

Sentry.init({
	dsn: 'https://52869a23325ca1b3a06c6d5f1332b8d8@o4505719435821056.ingest.sentry.io/4505719436935168',
	tracesSampleRate: 1.0,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,
	ignoreErrors: ['TypeError: Failed to fetch'],

	// If you don't want to use Session Replay, just remove the line below:
	integrations: [new Replay()],
	enabled: !dev
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
